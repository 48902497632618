import actions from './actions'
import getters from './getters'
import mutations from './mutations'
// import plugin from './plugins'
import {createHelpers} from 'vuex-map-fields';

const { getAccountField, updateAccountField } = createHelpers({
  getterType: `getAccountField`,
  mutationType: `updateAccountField`,
});

export default {
  state: {
    addresses: [],
    templates: [],
    addressesRelation: null,
    address_id: '980209900032132',
    regions: [],
    localities: [],
    streets: [],
    form: {
      address: {
        region: null,
        locality: null,
        street: null,
        building: null,
        corpus: null,
        apartment: null,
        block: null
      },
      template: {}
    }
  },
  actions,
  getters: {
    ...getters,
    getAccountField,
  },
  mutations: {...mutations, updateAccountField,}
  // plugins: [plugin]
}
