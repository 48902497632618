<template>
<div id="app">
  <v-app class="grey lighten-4">
    <Header/>
    <v-main>
        <router-view :key="$route.path"/>
    </v-main>
    <Footer/>
  </v-app>
</div>
</template>

<script>
// import Header from '@/components/main/TheHeader'
import Footer from '@/components/main/TheFooter'

export default {
  name: 'App',
  components: {
    // Header,
    Footer
  }
}
</script>

<style lang="scss">
  .theme--light.v-application {
    background: #f5f5f5 !important;
    font-family: 'PF DinDisplay Pro', sans-serif !important;
  }
</style>
