export default {
  tree: state => state.tree,
  getChildrensCount: state => id => {
    if (state.filteredTree.length) {
      let elem = state.filteredTree.filter(item => item.slug === id);

      if (elem.length) {
        return state.filteredTree.filter(item => item.parent_id === elem[0].id).length
      } else {
        return 0
      }
    } else {
      let elem = state.tree.filter(item => item.slug === id);
      if (elem.length) {
        return state.tree.filter(item => item.parent_id === elem[0].id).length
      } else {
        return 0
      }
    }

  },
  getChildrens: state => id => {
    if (state.filteredTree.length) {
      let elem = state.filteredTree.filter(item => item.slug === id);

      if (elem.length) {
        return state.filteredTree.filter(item => item.parent_id === elem[0].id)
      } else {
        return []
      }
    } else {
      let elem = state.tree.filter(item => item.slug === id);
      if (elem.length) {
        return state.tree.filter(item => item.parent_id === elem[0].id)
      } else {
        return []
      }
    }

  },
  getRoot: state => {
    if (state.filteredTree.length) {
      return state.filteredTree.filter(item => item.parent_id === null)
    } else {
      return state.tree.filter(item => item.parent_id === null)
    }
  },
  getTreeRegions: state => state.treeRegions,
  getSchemaTree: state => state.schemaTree,
  getRegionObject: state => {
    const region = state.treeRegions.filter(item => item.schema === state.schemaTree);
    if (region.length) {
      return region[0];
    } else {
      if (state.treeRegions.length) {
        return state.treeRegions[0];
      } else {
        return undefined
      }
    }
  }
}
