import lscache from '@/lib/lscache'
import {LocalityAPI, StreetAPI} from '@/api/locality'
import {SEARCH_ACCOUNT_LOCALITY_PRELOAD, SEARCH_ACCOUNT_LOCALITY_REGIONS, SEARCH_ACCOUNT_LOCALITY, SEARCH_ACCOUNT_LOCALITY_STREET} from './mutation-types';

const LOCALITY_REGIONS_TIME = 60; // Cache regions for 1 hour

export default {
  [SEARCH_ACCOUNT_LOCALITY_REGIONS]: ({commit}) => {
    return new Promise((resolve, reject) => {
      const data = JSON.parse(lscache.get('account-regions'));

      if (data) {
        commit(SEARCH_ACCOUNT_LOCALITY_REGIONS, data);
        resolve({data});
      } else {
        LocalityAPI.regions()
          .then(response => {
            const regions = response.data;
            lscache.set('account-regions', JSON.stringify(regions), LOCALITY_REGIONS_TIME);
            commit(SEARCH_ACCOUNT_LOCALITY_REGIONS, regions);
            resolve(response);
          })
          .catch(err => {
            reject(err);
          })
      }
    })
  },
  [SEARCH_ACCOUNT_LOCALITY]: async ({commit}, {schema, query}) => {
    const {data} = await LocalityAPI.search(schema, query);
    commit(SEARCH_ACCOUNT_LOCALITY, data);
  },
  [SEARCH_ACCOUNT_LOCALITY_PRELOAD]: async ({commit}, {schema}) => {
    const {data} = await LocalityAPI.preload(schema);
    commit(SEARCH_ACCOUNT_LOCALITY_PRELOAD, data);
  },
  [SEARCH_ACCOUNT_LOCALITY_STREET]: async ({commit}, {schema, locality_id, query}) => {
    const {data} = await StreetAPI.search(schema, locality_id, query);
    commit(SEARCH_ACCOUNT_LOCALITY_STREET, data);
  }
}
