import Vue from 'vue'
import Router from 'vue-router'
import HistoryView from '@/components/history/historyMain'

Vue.use(Router);

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/NotAuthHomePage.vue'),
      meta: {
        public: true,  // Allow access to even if not logged in
      }
    },
    {
      path: '/forms',
      name: 'forms',
      component: () => import('./components/GenerateForm.vue'),
      meta: {
        public: true,  // Allow access to even if not logged in
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/user/Login.vue'),
      meta: {
        public: true,  // Allow access to even if not logged in
        isAuthenticated: false // Do not allow user to visit login page or register page if they are logged in
      }
    },
    {
      path: '/user/password/reset/message',
      name: 'reset-password-message',
      component: () => import('./views/user/ResetPasswordMessage.vue'),
      meta: {
        public: true,  // Allow access to even if not logged in
      }
    },
    {
      path: '/user/password/reset',
      name: 'reset-password',
      component: () => import('./views/user/ResetPassword.vue'),
      meta: {
        public: true,  // Allow access to even if not logged in
      }
    },
    {
      path: '/user/card/list',
      name: 'user-card-list',
      component: () => import('./views/user/CardList.vue'),
      meta: {
        public: false,  // Allow access to even if not logged in
        isAuthenticated: true // Do not allow user to visit login page or register page if they are logged in
      }
    },
    {
      path: '/registration',
      name: 'registration',
      component: () => import('./views/user/Registration.vue'),
      meta: {
        public: true,  // Allow access to even if not logged in
        isAuthenticated: false // Do not allow user to visit login page or register page if they are logged in
      }
    },
    {
      path: '/account/dashboard',
      name: 'account-dashboard',
      component: () => import('./views/Dashboard.vue'),
    },
    {
      path: '/payment/confirm',
      name: 'confirmCheckout',
      component: () => import('./components/payments/ConfirmCheckout.vue'),
      meta: {
        public: true,  // Allow access to even if not logged in
      }
    },
    {
      path: '/card/list',
      name: 'cards-list',
      component: () => import('./components/payments/CardSelect.vue'),
      meta: {
        public: true,  // Allow access to even if not logged in
        isAuthenticated: true // Do not allow user to visit login page or register page if they are logged in
      }
    },
    {
      path: '/:schema/account/view/:secret_id',
      name: 'not-authenticated-account-view',
      component: () => import('./views/AccountView.vue'),
      props: true,
      meta: {
        public: true
      },
    },
    {
      path: '/ternopil/:secret_id',
      name: 'ternopil-account-view-short',
      component: () => import('./views/AccountView.vue'),
      props: true,
      meta: {
        public: true
      }
    },
    {
      path: '/rivne/:secret_id',
      name: 'rivne-account-view-short',
      component: () => import('./views/AccountView.vue'),
      props: true,
      meta: {
        public: true
      }
    },
    {
      path: '/ifrankivsk/:secret_id',
      name: 'ifrankivsk-account-view-short',
      component: () => import('./views/AccountView.vue'),
      props: true,
      meta: {
        public: true
      }
    },
    {
      path: '/kyiv/:secret_id',
      name: 'kyiv-account-view-short',
      component: () => import('./views/AccountView.vue'),
      props: true,
      meta: {
        public: true
      }
    },
    {
      path: '/lviv/:secret_id',
      name: 'lviv-account-view-short',
      component: () => import('./views/AccountView.vue'),
      props: true,
      meta: {
        public: true
      }
    },
    {
      path: '/khmelnytskyi/:secret_id',
      name: 'khmelnytskyi-account-view-short',
      component: () => import('./views/AccountView.vue'),
      props: true,
      meta: {
        public: true
      }
    },
    {
      path: '/chernivtsi/:secret_id',
      name: 'chernivtsi-account-view-short',
      component: () => import('./views/AccountView.vue'),
      props: true,
      meta: {
        public: true
      }
    },
    {
      path: '/account/view/:schema/:secret_id',
      name: 'authenticated-account-view',
      component: () => import('./views/AddressView.vue'),
      props: true
    },
    {
      path: '/history/:id/view',
      name: 'history-view',
      component: HistoryView,
      props: true
    },
    {
      path: '/cart',
      name: 'cart-view',
      component: () => import('./views/Cart.vue')
    },
    {
      path: '/profile/edit',
      name: 'profile-view',
      component: () => import('./views/user/Profile.vue'),
      meta: {
        public: false
      }
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('./views/About.vue'),
      meta: {
        public: true
      },
    },
    {
      path: '/terms/personal_data',
      name: 'personal-data',
      component: () => import('./views/user/PersonalData.vue'),
      meta: {
        public: true
      },
    },
    {
      path: '/search',
      name: 'search',
      component: () => import('./views/NotAuthHomePage.vue'),
      meta: {
        public: true
      }
    },
    // {
    //   path: '/services/*:id?',
    //   name: 'sub-services',
    //   component: () => import('./components/templates/CategoriesList.vue'),
    //   meta: {
    //     public: true
    //   },
    //   props: true,
    // },
    {
      path: '/templates*',
      name: 'templates',
      component: () => import('./components/templates/CategoriesList.vue'),
      meta: {
        public: true
      }
    },
    // {
    //   path: '/services',
    //   name: 'services',
    //   component: () => import('./components/templates/CategoriesList.vue'),
    //   meta: {
    //     public: true
    //   }
    // },
    {
      path: '/template/view/:schema/:group',
      name: 'services-view',
      component: () => import('./components/templates/SubCategoriesList.vue'),
      meta: {
        public: true
      },
      props: true,
    },

    // {
    //   path: '/services/',
    //   name: 'services-list',
    //   component: () => import('./components/templates/CategoriesList.vue'),
    //   meta: {
    //     public: true
    //   }
    // },
    {
      path: '*',
      name: 'page-not-found',
      component: () => import('./views/PageNotFound.vue'),
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      }
    }
  }
});

router.beforeEach((to, from, next) => {
  // if (to.name === 'home') {
  //   if (store.getters.isAuthenticated) {
  //     return next({name: 'account-dashboard'})
  //   } else {
  //     next()
  //   }
  // }

  if (to.name !== 'home') {
    return next('/')
  }

  //
  // if (!to.meta.public) {
  //   return next('/')
  // }
  //
  // if (to.meta.isAuthenticated) {
  //   return next('/')
  // }
  next()
      //   if (store.getters.isAuthenticated) {
  //     return next();
  //   } else {
  //     return next({
  //       path: '/login',
  //       query: {redirect: to.fullPath}  // Store the full path to redirect the user to after login
  //     });
  //   }
  // } else {
  //   if (to.meta.isAuthenticated && to.meta.isAuthenticated === false && store.getters.isAuthenticated) {
  //     return next('/');
  //   }
  //   next();
  // }
  }
);

export default router;
