<template>
  <div class="history">
    <div class="grey lighten-4">
      <v-container fluid class="container-max-width py-0 grey--text text--darken-2">
        <div class="grey lighten-4 py-4">
          <v-row no-gutters class="flex-nowrap" align="start">
            <span>
              <v-icon
                size="56px"
                color="primary"
                class="pr-4">
                payment
              </v-icon>
            </span>
            <div>
              <h3 class="text-uppercase">Комунальні платежі "Єдина квитанція"</h3>
              <p class="mb-1">Цей сервіс був розроблений задля економії Вашого часу та зручності в оплаті комунальних та інших послуг</p>
            </div>
          </v-row>
        </div>
      </v-container>
    </div>

  <v-container fluid grid-list-lg class="container-max-width pt-0">
    <v-card flat class="mb-3">
      <v-card-text>
        <v-row no-gutters  >
          <v-col md="3" sm="4" xs="6">
            <datePickerInMenu v-model="dateStart" :label="`Початок`"></datePickerInMenu>
          </v-col>
          <v-col md="3" sm="4" xs="6">
            <datePickerInMenu v-model="dateEnd" :label="`Закінчення`"></datePickerInMenu>
          </v-col>
          <v-col md="4" sm="4" xs="12">
            <v-select
              :items="operations"
              item-text="text"
              item-value="value"
              label="Операція"
              hide-details
              outlined
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card flat>
      <v-container fluid grid-list-xs class="px-2 pt-2 pb-0">
        <v-row no-gutters  align-space-around row fill-height
                  v-for="(item, index) in historyData" :key="index"
                  class="layout-border"
                  :class="`${index + 1 === historyData.length ? 'mb-0' : 'mb-2'}`"
        >
          <v-col md="3" sm="3" xs="2" class="success darken-1 layout-border-right">
            <v-row no-gutters  align="center" justify="center" column fill-height class="text-md-center text-sm-center">
              <div class="my-auto white--text success darken-1" style="width: 100%">
                <span class="font-weight-bold title hidden-xs-only px-1">Дата транзакції</span>
                <div class="">
                  <span class="font-weight-medium body-1 hidden-xs-only px-1">{{ item.date }}</span>
                </div>
              </div>
            </v-row>
          </v-col>
          <v-col md="9" sm="9" xs="10" >
            <v-row no-gutters  class="hidden-sm-and-up">
              <v-col xs="12" class="success darken-1 white--text text-xs-right">Дата транзакції: {{ item.date }}</v-col>
            </v-row>
            <v-row no-gutters   v-for="(historyItem, historyIndex) in item.table" :key="historyIndex" :class="`${historyIndex + 1 === item.table.length ? '' : 'layout-border-bottom'}`">
              <v-col md="2" sm="4" xs="12" class="text-md-center">
                <v-chip
                  :color="`${historyItem.meter ? 'success lighten-1' : 'accent'}`"
                  text-color="white"
                  class="operation-chip mr-1"
                >
                  <v-icon left v-text="`${historyItem.meter ? 'av_timer' : 'credit_card'}`"></v-icon>
                  <span class="text-md-center">{{ getOperationTranslate(historyItem.operation) }}</span>
                </v-chip>
              </v-col>
              <v-col md="3" sm="4" xs="6">
                <v-row no-gutters   align="center" class="pt-2">
                  <span class="pl-1" :class="`${$vuetify.breakpoint.xsOnly ? 'pl-2' : ''}`">
                    <i :class="`${getIcon(undefined)} pr-3`"></i>
                  </span>
                  <div class="text-md-left text-sm-left">
                    <span class="d-block body-2 grey--text text-no-wrap">{{ historyItem.service.name }}</span>
                    <span class="d-block body-1 grey--text text--darken-2">{{ historyItem.person_id_internal }}</span>
                  </div>
                </v-row>
              </v-col>
              <v-col md="7" sm="4" class="hidden-sm-and-down">
                <v-row no-gutters  >
                  <v-col md="4" sm="4" xs="6" v-if="!historyItem.meter" class="text-md-right">
                    <div>
                      <span class="d-block body-2 grey--text text-no-wrap">Оплачено</span>
                      <span class="d-block body-1 grey--text text--darken-2">{{ historyItem.sum }}</span>
                    </div>
                  </v-col>
                  <v-col md="4" sm="4" xs="6" class="text-md-right" v-if="historyItem.meter">
                    <div v-if="historyItem.meter">
                      <span class="d-block body-2 grey--text text-no-wrap">№ лічильника</span>
                      <span class="d-block body-1 grey--text text--darken-2">{{ historyItem.meter }}</span>
                    </div>
                  </v-col>
                  <v-col sm="4" class="hidden-md-and-up hidden-xs-only" v-if="historyItem.meter"></v-col>
                  <v-col md="4" sm="4" xs="6" class="text-md-right" v-if="historyItem.meter">
                    <div v-if="historyItem.meter">
                      <span class="d-block body-2 grey--text text-no-wrap">Попередній показ</span>
                      <span class="d-block body-1 grey--text text--darken-2">{{ historyItem.prev }}</span>
                    </div>
                  </v-col>
                  <v-col md="4" sm="4" xs="6" class="text-md-right" v-if="historyItem.meter">
                    <div v-if="historyItem.meter" class="pr-3">
                      <span class="d-block body-2 grey--text text-no-wrap">Поточний показ</span>
                      <span class="d-block body-1 grey--text text--darken-2">{{ historyItem.next }}</span>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="2" sm="4" xs="6" v-if="!historyItem.meter" class="text-md-right hidden-md-and-up">
                <div>
                  <span class="d-block body-2 grey--text text-no-wrap">Оплачено</span>
                  <span class="d-block body-1 grey--text text--darken-2">{{ historyItem.sum }}</span>
                </div>
              </v-col>
              <v-col md="2" sm="4" xs="6" class="text-md-right hidden-md-and-up" v-if="historyItem.meter">
                <div v-if="historyItem.meter">
                  <span class="d-block body-2 grey--text text-no-wrap">№ лічильника</span>
                  <span class="d-block body-1 grey--text text--darken-2">{{ historyItem.meter }}</span>
                </div>
              </v-col>
              <v-col sm="4" class="hidden-md-and-up hidden-xs-only" v-if="historyItem.meter"></v-col>
              <v-col md="3" sm="4" xs="6" class="text-md-right hidden-md-and-up" v-if="historyItem.meter">
                <div v-if="historyItem.meter">
                  <span class="d-block body-2 grey--text text-no-wrap">Попередній показ</span>
                  <span class="d-block body-1 grey--text text--darken-2">{{ historyItem.prev }}</span>
                </div>
              </v-col>
              <v-col md="2" sm="4" xs="6" class="text-md-right hidden-md-and-up" v-if="historyItem.meter">
                <div v-if="historyItem.meter" class="pr-3">
                  <span class="d-block body-2 grey--text text-no-wrap">Поточний показ</span>
                  <span class="d-block body-1 grey--text text--darken-2">{{ historyItem.next }}</span>
                </div>
              </v-col>
<!--              <v-col md="2" sm="4" xs="6" v-if="!!historyItem.meter" class="hidden-sm-and-down"></v-col>-->
            </v-row>
          </v-col>
      </v-row>
      </v-container>
    </v-card>
  </v-container>

  </div>
</template>

<script>
  import datePickerInMenu from '@/components/datePickers/datePickerInMenu'
  import {getIcon} from '@/utils'
    export default {
      name: "historyMain",
      props: {
        id: {
          type: String,
          required: true
        },
      },
      data () {
        return {
          historyData: [],
          dateStart: null,
          dateEnd: null,
          operations: [
            {text: 'Усі', value: null},
            {text: 'Оплати', value: 'pays'},
            {text: 'Покази', value: 'meters'},
          ]
        }
      },
      methods: {
        getHistoryData () {
          const jsonData = [
            {
              date: '2019-01-01',
              table: [
                {operation: 'pays', service: { name: 'Управ. буд' }, person_id_internal: '656456', sum: '151.00',  meter: '', prev: '', next: ''},
                {operation: 'pays', service: { name: 'ОСББ' }, person_id_internal: '376743', sum: '51.00',  meter: '', prev: '', next: ''},
                {operation: 'pays', service: { name: 'Газ' }, person_id_internal: '2343', sum: '51.00',  meter: '', prev: '', next: ''},
                {operation: 'meters', service: { name: 'Газ' }, person_id_internal: '2343', sum: '',  meter: '1111111', prev: '1555', next: '1558'},
                {operation: 'meters', service: { name: 'Газ' }, person_id_internal: '2343', sum: '',  meter: '2222222', prev: '15', next: '120'},
                {operation: 'meters', service: { name: 'Електрика' }, person_id_internal: '22222', sum: '',  meter: '1111111', prev: '2555', next: '2558'},
                {operation: 'meters', service: { name: 'Електрика' }, person_id_internal: '22222', sum: '',  meter: '2222222', prev: '25', next: '220'},
              ]
            },
            {
              date: '2019-01-02',
              table: [
                {operation: 'pays', service: { name: 'Управ. буд' }, person_id_internal: '656456', sum: '151.00',  meter: '', prev: '', next: ''},
                {operation: 'pays', service: { name: 'ОСББ' }, person_id_internal: '376743', sum: '51.00',  meter: '', prev: '', next: ''},
                {operation: 'pays', service: { name: 'Газ' }, person_id_internal: '2343', sum: '51.00',  meter: '', prev: '', next: ''},
                {operation: 'meters', service: { name: 'Газ' }, person_id_internal: '2343', sum: '',  meter: '1111111', prev: '1555', next: '1558'},
                {operation: 'meters', service: { name: 'Газ' }, person_id_internal: '2343', sum: '',  meter: '2222222', prev: '15', next: '120'},
                {operation: 'meters', service: { name: 'Електрика' }, person_id_internal: '22222', sum: '',  meter: '1111111', prev: '2555', next: '2558'},
                {operation: 'meters', service: { name: 'Електрика' }, person_id_internal: '22222', sum: '',  meter: '2222222', prev: '25', next: '220'},
              ]
            },
            {
              date: '2019-01-03',
              table: [
                {operation: 'pays', service: { name: 'Управ. буд' }, person_id_internal: '656456', sum: '151.00',  meter: '', prev: '', next: ''},
                {operation: 'pays', service: { name: 'ОСББ' }, person_id_internal: '376743', sum: '51.00',  meter: '', prev: '', next: ''},
                {operation: 'pays', service: { name: 'Газ' }, person_id_internal: '2343', sum: '51.00',  meter: '', prev: '', next: ''},
                {operation: 'meters', service: { name: 'Газ' }, person_id_internal: '2343', sum: '',  meter: '1111111', prev: '1555', next: '1558'},
                {operation: 'meters', service: { name: 'Газ' }, person_id_internal: '2343', sum: '',  meter: '2222222', prev: '15', next: '120'},
                {operation: 'meters', service: { name: 'Електрика' }, person_id_internal: '22222', sum: '',  meter: '1111111', prev: '2555', next: '2558'},
                {operation: 'meters', service: { name: 'Електрика' }, person_id_internal: '22222', sum: '',  meter: '2222222', prev: '25', next: '220'},
              ]
            }
          ];
          this.historyData = jsonData;
        },
        getOperationTranslate (operation) {
          if (operation === 'pays') {
            return 'Оплата'
          } else {
            return 'Покази'
          }
        },
        getIcon
      },
      components: {
        datePickerInMenu
      },
      beforeMount() {
        let date = new Date();
        let dateStart =  new Date(date.getFullYear(), date.getMonth() - 1, 1);
        let dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        this.dateStart = dateStart.toISOString().substr(0, 10);
        this.dateEnd = dateEnd.toISOString().substr(0, 10);

        this.getHistoryData()
      }
    }
</script>

<style scoped lang="scss">
  .container-max-width {
    max-width: 1560px;
  }
  .layout-border-bottom {
    border-bottom: 1px solid #dedede;
  }
  .layout-border-right {
    border-right: 1px solid #dedede;
  }
  .layout-border {
    border: 1px solid #dedede;
  }
  .operation-chip {
    margin-top: 4px;
    width: 104px !important;
  }
</style>

<style lang="scss">
  .operation-chip .v-chip__content {
    width: 104px !important;
  }
  .operation-chip .v-chip__content span {
    width: 100%;
  }
</style>
