import getters from './getters'
import mutations from './mutations'

export default {
  state: {
    loading: false,
    requestCounter: 0
  },
  getters,
  mutations
}
