import mutations from './mutations'

export default {
  state: {
    color: '',
    message: '',
    timeout: null,
    visible: false,
  },
  mutations
}
