import http from '@/lib/http'

const SERVICES_ROOT_URL = '/template/catalogue/root';
const SERVICES_TREE_URL = '/template/catalogue/tree';
const SERVICES_VIEW_URL = '/template/catalogue/view';


export const ServicesAPI = {
  root() {
    return http.post(SERVICES_ROOT_URL, {})
  },
  tree(payload) {
    return http.post(SERVICES_TREE_URL, payload)
  },
  view(payload) {
    return http.post(SERVICES_VIEW_URL, payload)
  }
};
