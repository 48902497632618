import { FETCH_DATA, CLEAR_DATA, UPDATE_DATA, TOGGLE_SELECTED } from './mutation-types'

function getAmount(obj) {
  let sum = 0;
  if (obj) {
    sum = obj.filter(item => item.selected && !item.hidden)
      .reduce((total, s) => {
          let a = s.sum;
          if (typeof a === 'string') {
            a = a.replace(',', '.').replace(' ', '');
          }
          return total + (+a)
        }, 0);
  }
  return sum;
}

export default {
  [FETCH_DATA]: (state, payload) => {
    state.detailData = payload;
    state.detailCounter = state.detailData.filter(item => item.hidden).length || 0;
    state.detailAmount = getAmount(state.detailData) || 0;
  },
  [CLEAR_DATA]: (state) => {
    state.detailData = [];
    state.detailCounter = 0;
    state.detailAmount = 0;
  },
  [UPDATE_DATA]: (state, payload) => {
    let row = state.detailData[payload.id];

    let oldSum = row.sum;
    if (typeof a === 'string') {
      oldSum = oldSum.replace(',', '.').replace(' ', '');
    }

    let newSum = payload.sum;
    if (typeof a === 'string') {
      newSum = newSum.replace(',', '.').replace(' ', '');
    }

    const oldHidden = row.hidden ? 1 : 0;
    const newHidden = payload.hidden ? 1 : 0;

    const oldSelected = row.selected;

    row.selected = payload.selected;
    row.delete = payload.delete;
    row.edit = payload.edit;
    row.person_id_internal_text = payload.person_id_internal_text;
    row.sum = newSum;
    row.hidden = payload.hidden;

    state.detailAmount = state.detailAmount - (!oldSelected ? 0 : +oldSum) + (row.selected && !row.hidden ? +newSum : 0);
    state.detailCounter = state.detailCounter -  oldHidden + newHidden;
  },
  [TOGGLE_SELECTED]: (state, payload) => {
    state.detailData.forEach(item => {
      if (item.hidden) {
        item.selected = false
      } else {
        if (item.selected !== payload) {
          item.selected = payload
        }
      }
    });
    state.detailAmount = getAmount(state.detailData) || 0;
  },
}
