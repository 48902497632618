import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
  state: {
    tree: [],
    filteredTree: [],
    schemaTree: undefined,
    treeRegions: []
  },
  actions: {...actions},
  getters: {...getters},
  mutations: {...mutations},
}
