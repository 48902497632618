export default {
  close: 'Закрити',
  dataIterator: {
    pageText: '{0}-{1} из {2}',
    noResultsText: 'В результаті пошуку нічого не знайдено',
    loadingText: 'Завантажується...',
  },
  dataTable: {
    itemsPerPageText: 'Елементів на сторінці:',
    ariaLabel: {
      sortDescending: ': Упорядочено по нисходящей. Активируйте, чтобы убрать сортировку.',
      sortAscending: ': Упорядочено во восходящей. Активируйте для упорядочивания по ниcходящей.',
      sortNone: ': Не упорядочено. Активируйте для упорядочивания по восходящей.',
    },
  },
  dataFooter: {
    itemsPerPageText: 'Елементів на сторінці:',
    itemsPerPageAll: 'Всі',
    nextPage: 'Наступна сторінка',
    prevPage: 'Попередня сторінка',
    firstPage: 'Перша сторінка',
    lastPage: 'Остання сторінка'
  },
  noDataText: 'Немає даних для відображення',
  carousel: {
    prev: 'Попередній візуальний',
    next: 'Наступний візуальний'
  },
  account: {
    service: 'Послуга',
    person_id_internal: 'Номер платника',
    month: 'Місяць',
    tie: 'Борг',
    paid_last_month: 'Оплачено минулого місяця',
    charge: 'Нараховано',
    subsidy: 'Субсидія',
    due: 'До оплати',
    paid_current_month: 'Оплачено цього місяця',
    due_diff: 'Залишок',
    sum: 'Сума',
    error: {
      not_found: 'Рахунок не знайдено'
    },
    address_dialog: {
      'title': 'Нова адреса',
      'add': 'Добавити',
      'close': 'Закрити',
      'indicates_required_field': 'означає, що поле - обов\'язкове',
      'region': 'Область',
      'locality': 'Населений пункт',
      'street': 'Вулиця',
      'building': 'Будинок',
      'corpus': 'Корпус',
      'apartment': 'Квартира',
      'block': 'Блок',
      'start_typing': 'Клацніть усередині текстового поля та почніть вводити текст',
    }
  },
  profile: {
    signin: 'Увійти',
    logout: 'Вийти',
    authentication: 'Ідентифікація',
    email: 'E-mail',
    login: 'Користувач',
    password: 'Пароль',
    edit: 'Редагувати'
  },
  header: {
    menu: {
      home: 'Домівка',
      help: 'Довідка',
      signup: 'Реєстрація'
    }
  },
  error: {
    http: {
      service_unavailable: 'Сервіс недоступний',
      forbidden: 'У доступі відмовлено',
      unauthorized: 'Несанкціонований доступ'
    },
    router: {
      bad_request: 'Неправильний запит'
    }
  }
};
