import {LOADER_START, LOADER_STOP} from './mutation-types'

export default {
  [LOADER_START]: (state) => {
    state.requestCounter++;
    state.loading = true;
  },
  [LOADER_STOP]: (state) => {
    state.requestCounter--;

    if (state.requestCounter === 0) state.loading = false;
  }
}
