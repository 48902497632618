import http from '@/lib/http'

const LOCALITY_REGIONS_URL = '/locality/regions';
const LOCALITY_SEARCH_URL = '/locality/search';
const LOCALITY_PRELOAD_URL = '/locality/preload';
const STREET_SEARCH_URL = 'locality/street/search';

const LocalityAPI = {
  regions() {
    return http.post(LOCALITY_REGIONS_URL, {})
  },
  search(schema, name) {
    return http.post(
      LOCALITY_SEARCH_URL,
      {
        schema,
        name
      }
    )
  },
  preload(schema) {
    return http.post(
      LOCALITY_PRELOAD_URL,
      {
        schema
      }
    )
  }
};

const StreetAPI = {
  search(schema, locality_id, name) {
    return http.post(
      STREET_SEARCH_URL,
      {
        schema,
        locality_id,
        name
      }
    )
  }
};

export {
  LocalityAPI,
  StreetAPI
};
