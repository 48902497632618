import {SNACKBAR_HIDE, SNACKBAR_MESSAGE, SNACKBAR_SHOW, SNACKBAR_TOGGLE, SNACKBAR_VISIBILITY} from './mutation-types'

export default {
  [SNACKBAR_SHOW]: state => {
    state.visible = true;
  },
  [SNACKBAR_HIDE]: state => {
    state.visible = false;
  },
  [SNACKBAR_TOGGLE]: state => {
    state.visible = !state.visible;
  },
  [SNACKBAR_VISIBILITY]: (state, status) => {
    state.visible = status;
  },
  [SNACKBAR_MESSAGE](state, snackbar) {
    state.color = snackbar.color;
    state.message = snackbar.message;
    state.timeout = snackbar.timeout;

    state.visible = true;
  }
}
