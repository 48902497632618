<template>
  <v-snackbar
    v-model="visibility"
    :top="top"
    :bottom="bottom"
    :right="right"
    :left="left"
    :color=color
    :timeout=timeout
  >
    {{ message }}
    <v-btn icon @click="visibility = false">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
  import {mapState} from 'vuex';
  import {SNACKBAR_VISIBILITY} from '@/store/modules/snackbar/mutation-types';

  export default {
    name: 'AppSnackBar',
    props: {
      top: {
        type: Boolean,
        required: false,
        default: false
      },
      bottom: {
        type: Boolean,
        required: false,
        default: false
      },
      right: {
        type: Boolean,
        required: false,
        default: false
      },
      left: {
        type: Boolean,
        required: false,
        default: false
      },
    },
    computed: {
      ...mapState({
        color: state => state.snackbar.color,
        timeout: state => state.snackbar.timeout,
        message: state => state.snackbar.message
      }),
      visibility: {
        get() {
          return this.$store.state.snackbar.visible;
        },
        set(status) {
          this.$store.commit(SNACKBAR_VISIBILITY, status);
        }
      }
    }
  };
</script>
