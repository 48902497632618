import {
  SET_PAYMENTS,
  CLEAR_PAYMENTS,
} from './mutation-types'


export default {
  [SET_PAYMENTS]: ({commit}, payload) => {
    commit(SET_PAYMENTS, payload);
  },
  [CLEAR_PAYMENTS]: ({commit}) => {
    commit(CLEAR_PAYMENTS);
  },
}
