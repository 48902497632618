import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
  state: {
      token: localStorage.getItem('user-token') || '',
      status: '',
      user: JSON.parse(localStorage.getItem('user'))
  },
  actions,
  getters,
  mutations
}
