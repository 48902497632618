import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
  state: {
    payments: [],
  },
  actions: {...actions},
  getters: {...getters},
  mutations: {...mutations},
}
