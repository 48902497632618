import {map, assign, each} from 'lodash'
import { PaymentTasAPI } from '../api/tasPayments'

const USEHASCONTRACT = false;

const PAY_STATUS = {
  'created': {class: 'info', ukr: 'Створено', id: 1},
  'checkedout': {class: 'info', ukr: 'Відправлено', id: 2},
  'removed': {class: 'error', ukr: 'Вилучено', id: 3},
  'pending': {class: 'warning', ukr: 'Очікування', id: 4},
  'paid': {class: 'success', ukr: 'Оплачено', id: 5},
  'canceled': {class: 'error', ukr: 'Відмінено', id: 6},
  'failed': {class: 'error', ukr: 'Помилковий', id: 7},
  'refunded': {class: 'error', ukr: 'Повернено', id: 8},
  'unknown': {class: 'error', ukr: 'Невідомий', id: 9},
};

const OPERATION_TYPE = {
  1: {icon: 'mdi-domain', name: 'Єдина квитанція', imageClass: 'primary'},
  2: {icon: 'mdi-note-outline', name: 'Шаблон', imageClass: 'success'},
  3: {icon: 'mdi-credit-card-multiple', name: 'Платіж по реквізитах', imageClass: 'info'}
};

const BILL_IDS =  {
  'ternopil': 3277,
  'rivne': 3342
}

function getIcon(config) {
  // services-icons-font icon-gaz2
  const iconList = {
    'osbb': 'services-icons-font icon-osbb',
    'intercom': 'services-icons-font icon-domofon',
    'cold_water': 'services-icons-font icon-water',
    'hot_water': 'services-icons-font icon-water2',
    'drainage_system': 'services-icons-font icon-vidvedennya',
    'water_supply': 'services-icons-font icon-water',
    'power_supply': 'services-icons-font icon-electro',
    'gas_supply': 'services-icons-font icon-gaz2',
    'heating': 'services-icons-font icon-teplo',
    'garage': 'services-icons-font icon-garage',
    'internet': 'services-icons-font icon-provider',
    'cabletv': 'services-icons-font icon-tv',
    'hd': 'services-icons-font icon-kvartplata',
    'waste_removal': 'services-icons-font icon-tpv',

  };

  if (!config) return 'services-icons-font icon-tax';
  return iconList[config.ui.icon] || 'services-icons-font icon-tax'
}

function addClassToNumber(int, cls='') {
  if (int > 0) {
    return 'red--text ' + cls
  } else if (int < 0) {
    return 'green--text ' + cls
  } else {
    return 'grey-text text--lighten-1 '
  }
}

function assignServices(services) {
  let filteredServices = USEHASCONTRACT ? services.filter(item => item.service.has_contract !== null) : services;

  if (filteredServices.length) {
    return map(filteredServices, (item) => {
      return assign(item, {
        // sum: item.due > 0 ? item.due / 100 : 0,
        // sum: item.service.has_contract ? item.hidden ? 0 : item.due > 0 ? item.due / 100 : 0 : 0,
        sum: getSelected(item.service.has_contract, item.hidden, item.due),
        due: item.service.has_contract ? item.due : 0,
        selected: getSelected(item.service.has_contract, item.hidden, item.due) > 0,
        edit: false,
        hidden: item.hidden ? item.hidden : false,
        delete: false,
        showDetails: false,
        person_id_internal_text: item.service.has_contract ? item.person_id_internal_text_updated || item.person_id_internal_text : item.person_id_internal_text_updated,
      })
    });
  }
}

function getSelected(hasContract = false, hidden = false, sum = 0) {
  return hasContract ? hidden ? 0 : sum > 0 ? sum / 100 : 0 : 0
}

function getServices(item) {
  let services = [];
  let serviceArray = this.originData.filter(dataItem => dataItem.group_id === item.group_id);
  each(serviceArray, itemArray => {
    let newService = {};
    newService.service = itemArray.service;
    newService.sum = itemArray.sum;
    newService.person_id_internal = itemArray.person_id_internal;
    newService.person_id_internal_text = itemArray.person_id_internal_text;
    services.push(newService)
  });
  return services
}

function assignMeters(meters, services) {
  let filteredServices = [];
  if (services) {
    filteredServices = USEHASCONTRACT ? services.filter(item => item.service.has_contract !== null) : services;
  }

  if ((meters && services && filteredServices.length)) {
    let newMeters = map(meters, (item) => {
      let service_new = filteredServices.filter(service_item => service_item.service.id === item.service_id);
      return assign(item, {
        service: service_new.length ? service_new[0].service : null,
        difference: 0,
        reading_last: 0,
        person_id_internal_text: service_new.length ? service_new[0].person_id_internal_text : '',
        hidden: service_new.length ? service_new[0].hidden : false,
      })
    });

    return newMeters.filter(item => item.service != null)
  }
}

function getSumPay(address) {
  if (address.services) {
    let sum = address.services.reduce((total, s) => {
      let current = s.selected ? s.sum : 0;
      return total + current
    }, 0);
    return sum.toFixed(2);
  } else {
    return '0.00'
  }
}

function getAddressTitle(person) {
    const street = person.street;
    const locality = street.locality;
    const corpus = person.corpus ? ' ' + person.corpus : '';
    const block = person.block ? ' ' + person.block : '';
    const apartment = person.apartment ? '/' + person.apartment : '';
    let LocalityPath = '';
    locality.path.forEach(item => {
      LocalityPath += item.type + item.name + ', ';
    });

    return `${LocalityPath}${street.type}${street.name_printable} ${person.building}${corpus}${block}${apartment}`
}

function getOperationType(item) {

  let COLUMN_ADDRESS = 'address' in item ? 'address' : 'address_title';
  let COLUMN_TEMPLATE = 'template' in item ? 'template' : 'template_title';


  if (item[COLUMN_ADDRESS] === null) {
    return 3
  } else {
    if (item[COLUMN_TEMPLATE] === null) {
      return 1
    } else {
      return 2
    }
  }
}

function getOperation(item) {
  const COLUMN_ADDRESS = 'address' in item ? 'address' : 'address_title';
  const COLUMN_TEMPLATE = 'template' in item ? 'template' : 'template_title';

  if (item[COLUMN_ADDRESS] === null) {
    return 'Платіж по реквізитах'
  } else {
    if (item[COLUMN_TEMPLATE] === null) {
      return 'Єдина квитанція'
    } else {
      return 'Шаблон'
    }
  }
}

function getImage(item) {
  return OPERATION_TYPE[item.operation_type].icon || 'mdi-credit-card';
}

function getImageClass(item) {
  return OPERATION_TYPE[item.operation_type].imageClass || 'warning';
}

function getChipClass(item) {
  if (item.state === undefined) {
    return 'info'
  }
  return PAY_STATUS[item.state].class || 'info';
}

function getStateTranslate(state) {
  return PAY_STATUS[state].ukr || state;
}

function getCartOperationImage(item) {
  if (item.operation_type === 1) {
    return 'mdi-domain'
  }
  else if (item.operation_type === 2) {
    return 'mdi-note-outline'
  }
  else if (item.operation_type === 3) {
    return 'mdi-credit-card-multiple'
  }
}

function formatDate(date, difference = 0, unit='month', format='DD.MM.YYYY') {
  return this.$moment(date, 'YYYY-MM-DD').add(difference, unit).format(format);
}
const regExp = `\\D`;

const VALIDATION_RULES = {
  required: v => !!v || 'Це поле є обов’язковим',
  email: v => /.+@.+\..+/.test(v) || 'Введіть коректний E-mail',
  equal: (v, text, field) => v === field || text,
  numbersInString: v => regExp.test(v) || 'Введення чисел заборонено',
  boolean: v => v === true || 'Для продовження відмітьте цю позначку',
  valueLength: (v, name, length) => {
    if (length) {
      return (v && v.length >= length) || `${name} має містити ${length} символів або більше`;
    }
    return true;
  },
};

function cols(col_lg=0, col_md=0, col_sm=0, col_xs=0) {
  const breakpoint = this.$vuetify.breakpoint.name;

  const brealpointList = {
    'xl': col_lg || col_md || '',
    'lg': col_lg || col_md || '',
    'md': col_md || '',
    'sm': col_sm || '',
    'xs': col_xs || col_sm || '',
  };

  return brealpointList[breakpoint] || 'auto'
}

function getPayService(code) {
  if (typeof(code) !== 'string') {
    return undefined;
  }
  const firstLetter = code.slice(0, 1);
  const twoLetter = code.slice(0, 2);

  const services = {
    '30': {name: 'Diners Club', service: 1, right: 'right: 0;'},
    '36': {name: 'Diners Club', service: 1, right: 'right: 0;'},
    '38': {name: 'Diners Club', service: 1, right: 'right: 0;'},
    '31': {name: 'JCB International', service: 2, right: 'right: 3px;'},
    '35': {name: 'JCB International', service: 2, right: 'right: 3px;'},
    '34': {name: 'American Express', service: 3, background: 'background: #108b97a3 !important'},
    '37': {name: 'American Express', service: 3, background: 'background: #108b97a3 !important'},
    '50': {name: 'Maestro', service: 4, right: 'right: 7px;', background: 'background: #203e57b0 !important'},
    '56': {name: 'Maestro', service: 4, right: 'right: 7px;', background: 'background: #203e57b0 !important'},
    '57': {name: 'Maestro', service: 4, right: 'right: 7px;', background: 'background: #203e57b0 !important'},
    '58': {name: 'Maestro', service: 4, right: 'right: 7px;', background: 'background: #203e57b0 !important'},
    '63': {name: 'Maestro', service: 4, right: 'right: 7px;', background: 'background: #203e57b0 !important'},
    '67': {name: 'Maestro', service: 4, right: 'right: 7px;', background: 'background: #203e57b0 !important'},
    '51': {name: 'MasterCard', service: 5, right: 'right: 7px;', background: 'background: #175b19b0 !important'},
    '52': {name: 'MasterCard', service: 5, right: 'right: 7px;', background: 'background: #175b19b0 !important'},
    '53': {name: 'MasterCard', service: 5, right: 'right: 7px;', background: 'background: #175b19b0 !important'},
    '54': {name: 'MasterCard', service: 5, right: 'right: 7px;', background: 'background: #175b19b0 !important'},
    '55': {name: 'MasterCard', service: 5, right: 'right: 7px;', background: 'background: #175b19b0 !important'},
    '60': {name: 'Discover', service: 6, background: 'background: #0000006b !important'},
    '62': {name: 'China UnionPay', service: 7, right: 'right: 7px;'},
    '2': {name: 'Мир', service: 8},
    '4': {name: 'VISA', service: 9, background: 'background: #974a10a3 !important'},
    '7': {name: 'УЭК', service: 10},
    '8': {name: '', service: 12},
    '9': {name: 'Простір', service: 11, background: 'background: #974a10a3 !important'},
  };

  return services[firstLetter]
    ? services[firstLetter] : services[twoLetter]
      ? services[twoLetter] : {};
}

function getCardLogo(id) {
  const logos = {
    1: 'images/PayServicesLogos/diners.svg',
    2: 'images/PayServicesLogos/jcb.svg',
    3: 'images/PayServicesLogos/amex.svg',
    4: 'images/PayServicesLogos/maestro.svg',
    5: 'images/PayServicesLogos/mastercard.svg',
    6: 'images/PayServicesLogos/discover.svg',
    7: 'images/PayServicesLogos/unionpay.svg',
    8: 'images/PayServicesLogos/mir.svg',
    9: 'images/PayServicesLogos/visa.svg',
    10: 'images/PayServicesLogos/visa.svg',
    11: 'images/PayServicesLogos/visa.svg',
    12: 'images/PayServicesLogos/visa.svg',
  };

  if (!id) {
    return 'images/PayServicesLogos/paypal.svg'
  }
  return logos[id] || 'images/PayServicesLogos/paypal.svg'
}

async function checkout(card_id, payments) {
  let success = null;
  let payload = {};

  try {
    const {data} = await PaymentTasAPI.checkout({ 'card': card_id, 'payments': payments });
    const [sum, id] = data;

    if (sum && id) {
      success = true;
      payload['sum'] = sum;
      payload['id'] = id;
    }
  } catch (e) {
    this.$snackbar("Платіж не підготовлено", 'error')
  }

  return await new Promise(function (resolve, reject) {
    if (success) {
      resolve( payload );
    } else {
      reject({});
    }
  });
}
async function confirm(id) {
  let success = null;
  let payload = {};
  try {
    const {data} = await PaymentTasAPI.confirm({ 'payment_id': id });
    if (data) {
      success = true;
      payload['payment_id'] = data.payment_id;
      payload['url'] = data.url;
    }
  } catch (e) {
    this.$snackbar("Платіж не підтверджено", 'error')
  }

  return await new Promise(function (resolve, reject) {
    if (success) {
      resolve(payload);
    } else {
      reject(payload);
    }
  });
}

async function google_pay(id) {
  let success = null;
  let payload = {};
  try {
    const {data} = await PaymentTasAPI.pay({ 'payment_id': id });
    if (data) {
      success = true;
      payload['payment_id'] = data.payment_id;
      payload['url'] = data.url;
    }
  } catch (e) {
    this.$snackbar("Платіж не підтверджено", 'error')
  }

  return await new Promise(function (resolve, reject) {
    if (success) {
      resolve(payload);
    } else {
      reject(payload);
    }
  });
}

export {
  getIcon,
  addClassToNumber,
  assignServices,
  getServices,
  assignMeters,
  getSumPay,
  getAddressTitle,
  getOperationType,
  getOperation,
  getCartOperationImage,
  formatDate,
  getPayService,
  getCardLogo,
  cols,
  getImage,
  getImageClass,
  getChipClass,
  getStateTranslate,
  checkout,
  confirm,
  google_pay,
  BILL_IDS,
  PAY_STATUS,
  OPERATION_TYPE,
  VALIDATION_RULES,
}
