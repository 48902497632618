import lscache from '@/lib/lscache'
import {LocalityAPI, StreetAPI} from '@/api/locality'
import {
  ACCOUNT_LOCALITY_PRELOAD,
  ACCOUNT_LOCALITY_REGIONS,
  ACCOUNT_LOCALITY_SEARCH,
  ACCOUNT_LOCALITY_STREET_SEARCH
} from './mutation-types';

const LOCALITY_REGIONS_TIME = 60;


export default {
  [ACCOUNT_LOCALITY_REGIONS]: async ({commit}) => {
    const data = JSON.parse(lscache.get('account-regions'));

    if (data) {
      commit(ACCOUNT_LOCALITY_REGIONS, data);
    } else {
      const {data} = await LocalityAPI.regions();
      lscache.set('account-regions', JSON.stringify(data), LOCALITY_REGIONS_TIME);
      commit(ACCOUNT_LOCALITY_REGIONS, data);
    }
  },
  [ACCOUNT_LOCALITY_SEARCH]: async ({commit}, {schema, query}) => {
    const {data} = await LocalityAPI.search(schema, query);
    commit(ACCOUNT_LOCALITY_SEARCH, data);
  },
  [ACCOUNT_LOCALITY_PRELOAD]: async ({commit}, {schema}) => {
    const {data} = await LocalityAPI.preload(schema);
    commit(ACCOUNT_LOCALITY_PRELOAD, data);
  },
  [ACCOUNT_LOCALITY_STREET_SEARCH]: async ({commit}, {schema, locality_id, query}) => {
    const {data} = await StreetAPI.search(schema, locality_id, query);
    commit(ACCOUNT_LOCALITY_STREET_SEARCH, data);
  },
}
