import http from '@/lib/http'

const LOGIN_URL = '/authentication/login';
const REGISTER_URL = '/user/create';
const VIEW_URL = '/user/view';
const UPDATE_URL = '/user/update';
const SEND_VERIFICATION_URL = '/user/sendverificationcode';
const CHECK_VERIFICATION_URL = '/user/checkverificationcode';
const CHECK_USER_NAME = '/user/checkusername';
const RESET_PASSWORD_MESSAGE_URL = 'user/sendpasswordresetmessage';
const RESET_PASSWORD_URL = '/user/resetpassword';
const CART_COUNTER = '/user/info';

export default {
  login (username, password) {
    return http.post(
      LOGIN_URL,
      {
        username,
        password
      }
    )
  },
  registration(payload) {
    return http.post(
      REGISTER_URL,
      {
        ...payload
      }
    )
  },
  view() {
    return http.post(
      VIEW_URL,
      {}
    )
  },
  update(payload) {
    return http.post(
      UPDATE_URL,
      {
        ...payload
      }
    )
  },
  sendVerification(payload) {
    return http.post(
      SEND_VERIFICATION_URL,
      {
        ...payload
      }
    )
  },
  checkVerification(payload) {
    return http.post(
      CHECK_VERIFICATION_URL,
      {
        ...payload
      }
    )
  },
  checkUserName(payload) {
    return http.post(
      CHECK_USER_NAME,
      {
        ...payload
      }
    )
  },
  sendResetPasswordOnEmail(username) {
    return http.post(
      RESET_PASSWORD_MESSAGE_URL,
      {
        username
      }
    )
  },
  resetPassword(payload) {
    return http.post(
      RESET_PASSWORD_URL,
      {
        ...payload
      }
    )
  },
  getCartCounter() {
    return http.post(
      CART_COUNTER,
      {}
    )
  }
};
