import {
  FETCH_DATA,
  UPDATE_DATA,
  TOGGLE_SELECTED,
  CLEAR_DATA
} from './mutation-types'

export default {
  [FETCH_DATA]: ({ commit }, payload) => {
    try {
      commit(FETCH_DATA, payload);
    } catch (e) {
      commit(FETCH_DATA, []);
    }
  },
  [CLEAR_DATA]: ({commit}) => {
    commit(CLEAR_DATA);
  },
  [UPDATE_DATA]: ({commit}, payload) => {
    commit(UPDATE_DATA, payload);
  },
  [TOGGLE_SELECTED]: ({commit}, payload) => {
    commit(TOGGLE_SELECTED, payload);
  }
}
