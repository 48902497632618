import getters from './getters'
import mutations from './mutations'

export default {
  state: {
    loading: {},
  },
  getters,
  mutations
}
