import axios from 'axios';
import store from '@/store';
import {LOADER_START, LOADER_STOP} from '@/store/modules/loader/mutation-types';
// const HTTP_BASE_URL = 'http://10.0.2.150:8080/api/pay24'; //work test
// const HTTP_BASE_URL = 'http://usi.eps.org.ua:8080/api/pay24'; //home test
const HTTP_BASE_URL = 'https://pay24.net.ua/api/pay24'; // development

const HTTP_TIMEOUT = 4; // seconds

class HTTPError extends Error {}

class HTTPResponseError extends HTTPError {}

class APIResponseError extends HTTPResponseError {}

const http = axios.create({
  baseURL: HTTP_BASE_URL,
  timeout: HTTP_TIMEOUT * 1000
});

const token = localStorage.getItem('user-token');

if (token) {
  http.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

http.interceptors.request.use(config => {
  store.commit(LOADER_START);
  return config;
}, error => {
  store.commit(LOADER_STOP);
  return Promise.reject(error);
});

http.interceptors.response.use(response => {
  store.commit(LOADER_STOP);
  return response;
}, error => {
  store.commit(LOADER_STOP);
  return Promise.reject(error);
});

export default http;
export {
  http,
  HTTPError,
  HTTPResponseError,
  APIResponseError
};
