<template>
  <div>
    <v-footer padless class="white py-1">
      <v-container fluid class="py-1 px-0">
        <v-row no-gutters   justify="center" align="center" class="py-1">
<!--          <v-col style="max-width: 90px">-->
<!--&lt;!&ndash;            <v-hover v-slot:default="{ hover }">&ndash;&gt;-->
<!--              <a href="https://tascombank.ua" target="_blank" class="d-block">-->
<!--&lt;!&ndash;              <a href="https://tascombank.ua" target="_blank" class="d-block" :class="{ 'on-hover-a-href': hover, 'on-hover-out-a-href': !hover  }">&ndash;&gt;-->
<!--                <v-img-->
<!--                  :src="`${publicPath}images/tas/image002_1.png`"-->
<!--                  :height="$vuetify.breakpoint.smAndDown ? '30px' : '40px'"-->
<!--                  :max-width="$vuetify.breakpoint.smAndDown ? '40px' : '50px'"-->
<!--                  aspect-ratio="1"-->
<!--                  class="greyscale-img mx-auto"-->
<!--                  contain-->
<!--                >-->
<!--                </v-img>-->
<!--              </a>-->
<!--&lt;!&ndash;            </v-hover>&ndash;&gt;-->
<!--          </v-col>-->
          <v-col style="max-width: 90px">
            <v-img
              :src="`${publicPath}images/payments/visa-logo.svg`"
              contain
              :height="$vuetify.breakpoint.smAndDown ? '30px' : '40px'"
              :max-width="$vuetify.breakpoint.smAndDown ? '40px' : '60px'"
              aspect-ratio="1"
              class="greyscale-img mx-auto"
            >
            </v-img>
          </v-col>
          <v-col style="max-width: 90px">
            <v-img
              :src="`${publicPath}images/payments/mastercard-logo.svg`"
              contain
              :height="$vuetify.breakpoint.smAndDown ? '30px' : '35px'"
              :max-width="$vuetify.breakpoint.smAndDown ? '40px' : '50px'"
              aspect-ratio="1"
              class="greyscale-img mx-auto"
            >
            </v-img>
          </v-col>
          <v-col style="max-width: 90px">
            <v-img
              :src="`${publicPath}images/payments/verified-by-visa-logo.svg`"
              contain
              :height="$vuetify.breakpoint.smAndDown ? '30px' : '35px'"
              :max-width="$vuetify.breakpoint.smAndDown ? '40px' : '50px'"
              aspect-ratio="1"
              class="greyscale-img mx-auto"
            >
            </v-img>
          </v-col>
          <v-col style="max-width: 90px">
            <v-img
              :src="`${publicPath}images/payments/mastercard-securecode-logo.svg`"
              contain
              :height="$vuetify.breakpoint.smAndDown ? '30px' : '40px'"
              :max-width="$vuetify.breakpoint.smAndDown ? '50px' : '70px'"
              aspect-ratio="1"
              class="greyscale-img mx-auto"
            >
            </v-img>
          </v-col>
        </v-row>
        <v-divider/>
        <v-row no-gutters justify="center" align="end" class="py-2 grid-list-lg flex-nowrap" style="font-size: 1.09rem; font-family: sans-serif;}">
          <v-col cols="auto" class="text-right pr-2">
            <div style="position: relative;">
              <v-icon size="20px" color="primary lighten-1">mdi-phone-in-talk-outline</v-icon>
              <span class="pl-1"><a href="tel:+380962636049" class="contact-href grey--text text--darken-1">096 263 60 49</a></span>
            </div>
          </v-col>
          <v-col cols="auto" class="text-left pl-2">
            <div style="position: relative;">
              <v-icon size="21px" color="primary lighten-1">mdi-email</v-icon>
              <span class="pl-1"><a href="mailto: support@pay24.net.ua" class="contact-href grey--text text--darken-1">support@pay24.net.ua</a></span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
    <AppSnackBar :bottom=true />
  </div>
</template>

<script>
    import AppSnackBar from '@/components/global/AppSnackBar';

    export default {
        name: 'Footer',
        data() {
            return {
                publicPath: process.env.BASE_URL
            }
        },
        components: {AppSnackBar}
    }
</script>

<style scoped lang="scss">
  .contact-href {
    text-decoration: none;
  }
  .on-hover-a-href {
    &::v-deep .v-image__image {
      transform: scale(1.2);
      transition: .5s;
    }
  }
  .on-hover-out-a-href {
    &::v-deep .v-image__image {
      transition: .3s;
    }
  }
</style>
