import http from '@/lib/http'
import AuthenticateAPI from '@/api/user'
import {AUTH_ERROR, AUTH_LOGOUT, AUTH_REQUEST, AUTH_SUCCESS} from './mutation-types';
import {SET_CART_COUNT_LOCAL_STORAGE, CLEAR_CART_COUNT} from "../cart/mutation-types";
// import { SET_CART_COUNT_LOCAL_STORAGE } from '@/store/modules/cart/mutation-types'

export default {
  [AUTH_REQUEST]: ({commit}, {username, password}) => {
    return new Promise((resolve, reject) => { // The Promise used for router redirect in login
      commit(AUTH_REQUEST);

      AuthenticateAPI.login(username, password)
        .then(response => {
          const {user, token} = response.data;

          if (user) {
            localStorage.setItem('user', JSON.stringify(user)); // store the user in localstorage
            localStorage.setItem('user-token', token); // store the token in localstorage
            http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            commit(AUTH_SUCCESS, {user, token});
            commit(SET_CART_COUNT_LOCAL_STORAGE);
            resolve(response);
          } else {
            commit(AUTH_ERROR, 'Логін або пароль не вірний');
            localStorage.removeItem('user'); // if the request fails, remove any possible user if possible
            localStorage.removeItem('user-token'); // if the request fails, remove any possible user token if possible
            commit(CLEAR_CART_COUNT);
            reject('Логін або пароль не вірний');
          }

          // you have your token, now log in your user :)
          // dispatch(USER_REQUEST);
        })
        .catch(err => {
          commit(AUTH_ERROR, err);
          localStorage.removeItem('user'); // if the request fails, remove any possible user if possible
          localStorage.removeItem('user-token'); // if the request fails, remove any possible user token if possible
          commit(CLEAR_CART_COUNT);
          reject(err);
        })
    })
  },
  [AUTH_LOGOUT]: ({commit}) => {
    return new Promise((resolve) => {
      commit(AUTH_LOGOUT);
      localStorage.removeItem('user'); // clear your user from localstorage
      localStorage.removeItem('user-token'); // clear your user's token from localstorage
      commit(SET_CART_COUNT_LOCAL_STORAGE);
      delete http.defaults.headers.common['Authorization'];
      resolve();
    })
  }
}
