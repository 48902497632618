import {
  SET_CART_COUNT_LOCAL_STORAGE,
  SET_CART_COUNT,
  CHANGE_CART_COUNT,
  CLEAR_CART_COUNT
} from './mutation-types'

// const user = localStorage.getItem('user');
// let localUser = null;
// if (user) {
//   localUser = JSON.parse(user);
// }

function saveLocalUser(payload) {
  const user = localStorage.getItem('user');

  if (user) {
    let localUser = JSON.parse(user);
    localUser.cart_counter = payload;
    localStorage.setItem('user', JSON.stringify(localUser))
  }
}

export default {
  [SET_CART_COUNT_LOCAL_STORAGE]: (state) => {
    const user = localStorage.getItem('user');

    if (user) {
      let localUser = JSON.parse(user);
      state.cartCounter = localUser.cart_counter;
    } else {
      state.cartCounter = 0;
    }
  },
  [SET_CART_COUNT]: (state, payload) => {
    state.cartCounter = payload;
    saveLocalUser(state.cartCounter);
  },
  [CHANGE_CART_COUNT]: (state, payload) => {
    state.cartCounter += payload;
    saveLocalUser(state.cartCounter);
  },
  [CLEAR_CART_COUNT]: (state) => {
    state.cartCounter = 0;
    saveLocalUser(0);
  }
}
