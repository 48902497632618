import '@mdi/font/css/materialdesignicons.css'

import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import uk from '@/locale/vuetify/uk'

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { uk },
    current: 'uk'
  },
  icons: {
    iconfont: 'mdi',
  },
});
