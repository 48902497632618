import {updateField} from 'vuex-map-fields';
import {
  SEARCH_ACCOUNT_LOCALITY,
  SEARCH_ACCOUNT_LOCALITY_FORM_ADDRESS_LOCALITY,
  SEARCH_ACCOUNT_LOCALITY_FORM_ADDRESS_STREET,
  SEARCH_ACCOUNT_LOCALITY_PRELOAD,
  SEARCH_ACCOUNT_LOCALITY_REGIONS,
  SEARCH_ACCOUNT_LOCALITY_STREET,
  SEARCH_ACCOUNT_CLEAR
} from './mutation-types'

export default {
  [SEARCH_ACCOUNT_LOCALITY_REGIONS]: (state, regions) => {
    state.searchRegions = regions;
  },
  [SEARCH_ACCOUNT_LOCALITY]: (state, localities) => {
    state.searchLocalities = localities;
  },
  [SEARCH_ACCOUNT_LOCALITY_PRELOAD]: (state, localities) => {
    state.searchLocalities = localities;
  },
  [SEARCH_ACCOUNT_LOCALITY_STREET]: (state, streets) => {
    state.searchStreets = streets;
  },
  [SEARCH_ACCOUNT_LOCALITY_FORM_ADDRESS_LOCALITY]: (state, locality) => {
    state.searchForm.searchAddress.searchLocality = locality;
  },
  [SEARCH_ACCOUNT_LOCALITY_FORM_ADDRESS_STREET]: (state, street) => {
    state.searchForm.searchAddress.searchStreet = street;
  },
  [SEARCH_ACCOUNT_CLEAR]: (state) => {
    state.searchForm.searchAddress.searchRegion = null;
    state.searchForm.searchAddress.searchLocality = null;
    state.searchForm.searchAddress.searchStreet = null;
    state.searchForm.searchAddress.searchBuilding = null;
    state.searchForm.searchAddress.searchCorpus = null;
    state.searchForm.searchAddress.searchApartment = null;
    state.searchForm.searchAddress.searchBlock = null
  },
  updateField
}
