import {
  CLEAR_PAYMENTS,
  SET_PAYMENTS,
} from './mutation-types'

export default {
  [SET_PAYMENTS]: (state, payload) => {
    state.payments = payload
  },
  [CLEAR_PAYMENTS]: (state) => {
    state.payments = []
  },
}
