import { TREE_GET_ALL, TREE_GET_SCHEMA_TREE, TREE_SCHEMA_SET, TREE_REGIONS } from './mutation-types'

export default {
  [TREE_GET_ALL]: (state, payload) => {
    state.tree = Object.freeze(payload);
  },
  [TREE_GET_SCHEMA_TREE]: (state, payload) => {
    state.filteredTree = Object.freeze(payload) || [];
  },
  [TREE_SCHEMA_SET]: (state, payload) => {
    state.schemaTree = payload
  },
  [TREE_REGIONS]: (state, payload) => {
    state.treeRegions = payload
  }
}
