import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
  state: {
    detailData: [],
    detailAmount: 0,
    detailCounter: 0,
  },
  actions: {...actions},
  getters: {...getters},
  mutations: {...mutations},
}
