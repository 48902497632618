import actions from './actions'
import getters from './getters'
import mutations from './mutations'
// import plugin from './plugins'
import { createHelpers } from 'vuex-map-fields';

const { getSearchAccountField, updateSearchAccountField } = createHelpers({
  getterType: `getSearchAccountField`,
  mutationType: `updateSearchAccountField`,
});

export default {
  state: {
    searchRegions: [],
    searchLocalities: [],
    searchStreets: [],
    searchForm: {
      searchAddress: {
        searchRegion: null,
        searchLocality: null,
        searchStreet: null,
        searchBuilding: null,
        searchCorpus: null,
        searchApartment: null,
        searchBlock: null
      },
      template: {}
    }
  },
  actions,
  getters: {...getters, getSearchAccountField},
  mutations: {...mutations, updateSearchAccountField}
  // plugins: [plugin]
}
