import Vue from 'vue'
import uid from 'vue-uuid';
import App from '@/App'

import moment from 'moment'

import router from '@/router'
import vuetify from '@/plugins/vuetify'
import snackbar from '@/plugins/snackbar'
import store from '@/store'
import filters from '@/filters'
import NumberDirective from '@/directives/digitsNumber'
import {http, APIResponseError, HTTPResponseError} from '@/lib/http';
import {AUTH_LOGOUT} from '@/store/modules/authentication/mutation-types';
import { baseURL } from '@/mixins'
import VueGeolocation from 'vue-browser-geolocation';


// const  geoOptions = {
//   enableHighAccuracy: true, //defaults to false
//   timeout: 5000, //defaults to Infinity
//   maximumAge: 0 //defaults to 0
// }

const eventBus = new Vue();

Vue.prototype.$http = http;
Vue.prototype.$moment = moment;
Vue.prototype.$snackbar = snackbar;
Vue.prototype.$eventBus = eventBus;
Vue.config.productionTip = false;
Vue.config.silent = true;

Vue.use(filters);
Vue.use(uid);
Vue.use(VueGeolocation)
Vue.mixin(baseURL);
Vue.directive('restrict', NumberDirective);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  created() {
    moment.locale(this.$vuetify.lang.current);

    // Check for all unauthorized responses (HTTP 401) and if so, dispatch a logout action.
    this.$http.interceptors.response.use(response => {
      if (response.data && response.data.message) this.$snackbar(response.data.message, 'success');

      if (response.data && response.data.error) {
        this.$snackbar(response.data.error, 'error');
        return Promise.reject(new APIResponseError({message: response.data.error}));
      }

      return response;
    }, error => {
      if (error.response) {
        if (error.response.status === 401) {
          this.$snackbar(this.$vuetify.lang.t('$vuetify.error.http.unauthorized'), 'error');

          this.$store.dispatch(AUTH_LOGOUT)
            .then(() => {
              this.router.push('/login');
            });
        } else if (error.response.status === 403) {
          this.$snackbar(this.$vuetify.lang.t('$vuetify.error.http.forbidden'), 'error');
        } else {
          this.$snackbar(this.$vuetify.lang.t('$vuetify.error.http.service_unavailable'), 'error');
        }
      } else {
        this.$snackbar(this.$vuetify.lang.t('$vuetify.error.http.service_unavailable'), 'error');
      }

      return Promise.reject(new HTTPResponseError(error));
    })
    ;
  },
}).$mount('#app');
