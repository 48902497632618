import Vue from 'vue'
import Vuex from 'vuex'

import account from './modules/account'
import searchAccount from './modules/searchAccount'
import authentication from './modules/authentication'
import cart from './modules/cart'
import loader from './modules/loader'
import snackbar from './modules/snackbar'
import spinner from './modules/spinner'
import payments from './modules/payments'
import tree from './modules/tree'
import detail from './modules/detail'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  modules: {
    account,
    searchAccount,
    authentication,
    cart,
    loader,
    snackbar,
    spinner,
    payments,
    tree,
    detail
  },
  strict: true
})
