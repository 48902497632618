import {
  TREE_GET_ALL,
  TREE_GET_SCHEMA_TREE,
  TREE_SCHEMA_SET,
  TREE_REGIONS,
} from './mutation-types'

import { ServicesAPI } from '@/api/Services'
import lscache from '@/lib/lscache'
import {LocalityAPI} from '@/api/locality'

const LOCALITY_REGIONS_TIME = 600;

export default {
  [TREE_GET_ALL]: ({commit}) => {
    return new Promise((resolve, reject) => {
      let cDate = new Date();
      let expDate = localStorage.getItem('expTreeDate');
      let tree = localStorage.getItem('tree');

      if (!expDate) {
        expDate = cDate.setMinutes(cDate.getMinutes() - (60 * 12));
      }

      if ((!tree) || (expDate <= cDate )) {
        ServicesAPI.tree({ 'root': null })
          .then(payload => {
            let data = payload.data;
            commit(TREE_GET_ALL, data);

            let nDate = cDate.setMinutes(cDate.getMinutes() + (60 * 12));

            localStorage.setItem('expTreeDate', JSON.stringify(nDate));
            localStorage.setItem('tree', JSON.stringify(data));
            resolve();
          })
          .catch(() => {
            reject('Помилка одержання дерева послуг');
          })
      } else {
        commit(TREE_GET_ALL, JSON.parse(tree));
        resolve();
      }
    })
  },
  [TREE_GET_SCHEMA_TREE]: ({commit, state}, schema) => {
    return new Promise((resolve, reject) => {
      if (state.tree.length) {
        let filtered = state.tree.filter(item => {
          return item.id > 0 && schema
          // return (schema in item.localities)
        });
        commit(TREE_GET_SCHEMA_TREE, filtered);
        resolve(filtered);
      } else {
        reject('не вдалось отримати дерево для фільтрації')
      }
    })
  },
  [TREE_SCHEMA_SET]: ({commit}, payload) => {
    const data = JSON.parse(lscache.get('tree-region'));


    if (payload === '') {
      if (!data) {
        lscache.set('tree-region', JSON.stringify('kyiv'), LOCALITY_REGIONS_TIME);
        commit(TREE_SCHEMA_SET, 'kyiv');
      } else {
        commit(TREE_SCHEMA_SET, data);
      }
      return;
    }

    if (data !== payload || !data) {
      commit(TREE_SCHEMA_SET, payload);
      lscache.set('tree-region', JSON.stringify(payload), LOCALITY_REGIONS_TIME);
    } else {
      commit(TREE_SCHEMA_SET, data);
    }

  },
  [TREE_REGIONS]: ({commit}) => {
    return new Promise ((resolve, reject) => {
      const data = JSON.parse(lscache.get('tree-regions'));

      if (data) {
        commit(TREE_REGIONS, data);
        resolve(data)
      } else {
        try {
            LocalityAPI.regions()
              .then(payload => {
                let data = payload.data;
                lscache.set('tree-regions', JSON.stringify(data), LOCALITY_REGIONS_TIME);
                commit(TREE_REGIONS, data);
                resolve(data)
              }).catch(() => {
              reject('Не вдалось отримати перелік областей')
            });
        } catch (e) {
          reject('Не вдалось отримати перелік областей')
        }

      }
    })
  }
}
