import {
  SET_CART_COUNT_LOCAL_STORAGE,
  SET_CART_COUNT,
  CHANGE_CART_COUNT,
  CLEAR_CART_COUNT
} from './mutation-types'


export default {
  [SET_CART_COUNT_LOCAL_STORAGE]: ({commit}) => {
    commit(SET_CART_COUNT_LOCAL_STORAGE);
  },
  [SET_CART_COUNT]: ({commit}, payload) => {
    commit(SET_CART_COUNT, payload);
  },
  [CHANGE_CART_COUNT]: ({commit}, payload) => {
    commit(CHANGE_CART_COUNT, payload);
  },
  [CLEAR_CART_COUNT]: ({commit}) => {
    commit(CLEAR_CART_COUNT);
  }
}
