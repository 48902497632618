import http from '@/lib/http'

const PAYMENT_CHECKOUT_URL = '/payment/cart/checkout';
const PAYMENT_CONFIRM_URL_ = '/payment/cart/confirm';
const PAYMENT_PAY_GOOGLE_APPLE_URL = '/payment/cart/pay'
const PAYMENT_CHECK_STATE = '/payment/state';
const PAYMENT_VIEW = '/payment/cart/item';

const TIMEOUT = 7;

export const PaymentTasAPI = {
  checkout(payload) {
    return http.post(PAYMENT_CHECKOUT_URL, payload)
  },
  confirm(payload) {
    return http.post(PAYMENT_CONFIRM_URL_, payload, { timeout: TIMEOUT * 1000 })
  },
  pay(payload) {
    return http.post(PAYMENT_PAY_GOOGLE_APPLE_URL, payload, { timeout: TIMEOUT * 1000 })
  },
  state(payload) {
    return http.post(PAYMENT_CHECK_STATE, payload)
  },
  view(payload) {
    return http.post(PAYMENT_VIEW, payload)
  }
};
