import {updateField} from 'vuex-map-fields';
import {
  ACCOUNT_LOCALITY_FORM_ADDRESS_LOCALITY,
  ACCOUNT_LOCALITY_FORM_ADDRESS_STREET,
  ACCOUNT_LOCALITY_PRELOAD,
  ACCOUNT_LOCALITY_REGIONS,
  ACCOUNT_LOCALITY_SEARCH,
  ACCOUNT_LOCALITY_STREET_SEARCH
} from './mutation-types'
// import {assign, map} from "lodash";

export default {
  [ACCOUNT_LOCALITY_REGIONS]: (state, regions) => {
    state.regions = regions;
  },
  [ACCOUNT_LOCALITY_SEARCH]: (state, localities) => {
    state.localities = localities;
  },
  [ACCOUNT_LOCALITY_PRELOAD]: (state, localities) => {
    state.localities = localities;
  },
  [ACCOUNT_LOCALITY_STREET_SEARCH]: (state, streets) => {
    state.streets = streets;
  },
  [ACCOUNT_LOCALITY_FORM_ADDRESS_LOCALITY]: (state, locality) => {
    state.form.address.locality = locality;
  },
  [ACCOUNT_LOCALITY_FORM_ADDRESS_STREET]: (state, street) => {
    state.form.address.street = street;
  },
  updateField,
}
