<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="dateComponent"
    transition="scale-transition"
    offset-y
    full-width
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="computedDateFormatted"
        v-model="dateComponent"
        :label="label || 'Дата'"
        prepend-icon="mdi-calendar"
        outlined
        hide-details
        readonly
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="dateComponent" no-title scrollable @change="handleInput">
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">Відмінити</v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(dateComponent)">Підтвердити</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
  export default {
    name: "datePickerInMenu",
    props: ['value', 'label'],
    data() {
      return {
        dateComponent: this.value,
        menu: false
      }
    },
    computed: {
      computedDateFormatted () {
        return this.dateComponent ? this.$moment(this.dateComponent).format('dddd, MMMM Do YYYY') : ''
      }
    },
    methods: {
      handleInput() {
        this.$emit('input', this.dateComponent)
      }
    }
  }
</script>

<style scoped>

</style>
