import Vue from 'vue';

import {SPINNER_START, SPINNER_STOP} from './mutation-types'

export default {
  [SPINNER_START]: (state, name) => {
    Vue.set(state.loading, name, true);
  },
  [SPINNER_STOP]: (state, name) => {
    Vue.delete(state.loading, name);
  }
}
